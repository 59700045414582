import './style.css';
import 'react-datepicker/dist/react-datepicker.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Alignment,
  BlockQuote,
  Bold,
  ClassicEditor,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontSize,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Strikethrough,
  Table,
  TableToolbar,
  TodoList,
  Undo,
} from 'ckeditor5';
import { Formik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { Modal } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';

import { EnumScheduleReportStatus, EnumScheduleReportType } from '../../../../common/enums';
import { StudentClassRoomModel } from '../../../../common/models/_models';
import { ReportScheduleMessageForm, SearchReportScheduleResponse, UpdateReportScheduleMessage } from '../../core/_models';
import {
  deleteReportScheduleMessage,
  DOWNLOAD_REPORT_API,
  getReportScheduleMessage,
  updateReportScheduleMessage,
} from '../../core/_requests';
import SelectStudentModal from './SelectStudentModal';

type props = {
  handleClose: () => void,
  show: boolean,
  students: StudentClassRoomModel[],
  reportSchedule?: SearchReportScheduleResponse
  classRoomId: string
  type: string
}

const editorConfiguration = {
  toolbar: {
    items: [
      'undo', 'redo',
      '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
      '|',
      'fontsize', 'fontColor', 'fontBackgroundColor',
      '|',
      'bold', 'italic', 'strikethrough',
      '|',
      'blockQuote',
      '|',
      'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
      '|',
      'insertTable'

    ],
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  },
  plugins: [
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo,
    BlockQuote,
    TodoList,
    Strikethrough,
    FontSize,
    FontColor,
    FontBackgroundColor,
    TableToolbar,
    Alignment
  ],
  placeholder: 'Nhận xét của giáo viên'
};


interface SubmitForm {
  students: ReportScheduleMessageForm[]
}
const ReportStudentScheduleDetailModal: FC<props> = ({ show, handleClose, students, reportSchedule, classRoomId, type }) => {
  const [initialValues, setInitialValues] = useState<SubmitForm>({
    students: []
  });

  const [showSelectStudent, setShowSelectStudent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [existedStudentIds, setExistedStudentIds] = useState<string[]>([])
  const [selectedStudentIds, setSelectedStudentIds] = useState<string[]>([])

  useEffect(() => {
    if (show && students.length > 0 && reportSchedule?.id) {
      getMessage();
    } else {
      setInitialValues({
        students: []
      })
    }
  }, [show, reportSchedule])

  // useEffect(() => {
  //   if (initialValues.students.length) {
  //     // document.querySelectorAll("button[aria-label='Expand Row']").forEach((el: any) => el.click())

  //   }
  // }, [initialValues.students.length])


  const columns = [
    {
      name: 'Tên học sinh',
      selector: row => {
        return <>
          <div className='fw-bold'>{row.fullName}</div>
          <div className='small mt-1'>{row.phoneNumber}</div>
        </>
      }
    },
    {
      name: 'Email',
      selector: row => {
        return row.email
      }
    },
    {
      name: 'Tên phụ huynh',
      selector: row => {
        return <div>
          <div className='fw-bold'>{row.parentName}</div>
          <div className='small mt-1'>{row.parentPhone}</div>
        </div>
      }
    },
    {
      name: 'Gửi đến email',
      selector: row => {
        return <>
          {
            row.parentEmail === '' ? <div className='d-flex align-items-center text-danger'><span className="material-symbols-rounded fs-3 me-2">
              warning
            </span> Không thể gửi báo cáo</div> : <div className='fw-bold'>{row.parentEmail}</div>
          }

          <a className='mt-1 d-flex' href={`https://kella.edu.vn/report-schedule.html?id=${reportSchedule?.id}&uid=${row.studentId}`} target='_blank'>
            Chia sẻ
            <span className="ms-2 fs-3 material-symbols-rounded">
              share
            </span>

          </a>
        </>
      }
    },


  ] as TableColumn<ReportScheduleMessageForm>[];

  const getMessage = async () => {
    setLoading(true)
    if (reportSchedule?.id) {
      let { data } = await getReportScheduleMessage(classRoomId, reportSchedule?.id, students.map(x => x.id));
      if (data.data) {
        setExistedStudentIds(data.data.map(x => x.studentId))

        setInitialValues({
          students: data.data.map(x => {
            let student = students.find(s => s.id === x.studentId);
            return {
              message: x.message,
              studentId: x.studentId,
              email: student?.email,
              fullName: `${student?.firstName} ${student?.lastName}`,
              parentEmail: student?.parentEmail,
              parentName: student?.parentName,
              parentPhone: student?.parentPhone,
              phoneNumber: student?.parentPhone,
              dataJson: x.dataJson
            } as ReportScheduleMessageForm
          })
        })
      }
    }
    setLoading(false)
  }
  const handleConfirmed = async (values: SubmitForm) => {
    let isSuccess = true;

    if (reportSchedule) {
      let deletedStudents = existedStudentIds.filter(x => !values.students.some(s => s.studentId === x))
      if (deletedStudents.length) {
        await deleteReportScheduleMessage(classRoomId, reportSchedule?.id, deletedStudents)
      }

      const chunkSize = 3;
      for (let i = 0; i < values.students.length; i += chunkSize) {
        const chunk = values.students.slice(i, i + chunkSize).map(x => {
          return {
            message: x.message,
            studentId: x.studentId,
          } as UpdateReportScheduleMessage
        });

        let { data } = await updateReportScheduleMessage(classRoomId, reportSchedule?.id, chunk)
        isSuccess = data.data ?? false
      }

      if (isSuccess) {
        handleClose()
      }
    }
  }

  interface ReportWeekData {
    AttendancePercent: any
    HomeWorkPercent: any
    SkillName: string
    Score: number
  }

  interface ReportMonthData {
    AttendancePercent: number
    HomeWorkPercent: number
    WeekScores: WeekScore[]
    SkillName: string
    Score: number
  }

  interface WeekScore {
    SkillName: string
    Score: number
  }
  interface ReportSemesterData {
    sumAttendancePercent: number
    sumHomeWorkPercent: number
    LearningComment: string
    ListeningScore: number
    SpeakingScore: number
    ReadingScore: number
    WritingScore: number
    OverallScore: number
    ListeningComment: string
    SpeakingComment: string
    ReadingComment: string
    WritingComment: string
  }


  const ExpandedComponent =
    (props: any) => {
      let index = props.values.students.findIndex((x: any) => x.studentId === props.data.studentId);
      if (index !== -1) {
        let dataWeek = undefined as ReportWeekData | undefined;
        let dataMonth = undefined as ReportMonthData | undefined;
        let dataSemester = undefined as ReportSemesterData | undefined;

        if (props.values.students[index].dataJson) {
          if (type === EnumScheduleReportType.WeekReport) {
            dataWeek = JSON.parse(props.values.students[index].dataJson);
          }

          if (type === EnumScheduleReportType.MonthReport) {
            dataMonth = JSON.parse(props.values.students[index].dataJson);
          }

          if (type === EnumScheduleReportType.SemesterReport) {
            dataSemester = JSON.parse(props.values.students[index].dataJson);
          }
        }
        return <div className='mb-5 rounded bg-light-secondary  p-3 mx-2'>
          {
            dataWeek && <table className="table table-bordered mb-0 border-secondary table-sm mb-2">
              <thead>
                <tr className='text-center'>
                  <th scope="col">Điểm danh</th>
                  <th scope="col">Bài tập về nhà</th>
                  <th scope="col">{dataWeek?.SkillName}</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-center'>
                  <td>{dataWeek?.AttendancePercent} %</td>
                  <td>{dataWeek?.HomeWorkPercent} %</td>
                  <td>{dataWeek?.Score}</td>
                </tr>
              </tbody>
            </table>
          }
          {
            dataMonth && <table className="table table-bordered mb-0 border-secondary table-sm  mb-2">
              <thead>
                <tr className='text-center bg-light-secondary'>
                  <th scope="col">Điểm danh</th>
                  <th scope="col">Bài tập về nhà</th>
                  <th scope="col">{dataMonth?.SkillName}</th>
                  {dataMonth.WeekScores.map((x, i) => <th scope="col" >{x.SkillName}</th>)}
                </tr>
              </thead>
              <tbody>
                <tr className='text-center'>
                  <td>{dataMonth?.AttendancePercent} %</td>
                  <td>{dataMonth?.HomeWorkPercent} %</td>
                  <td>{dataMonth?.Score}</td>
                  {dataMonth.WeekScores.map((x, i) => <td>{x.Score}</td>)}
                </tr>
              </tbody>
            </table>
          }
          {
            dataSemester &&
            <div className='comment'>
              <h5>A. Ý thức học tập</h5>

              <table className="table table-bordered mb-0 border-secondary table-sm mb-2">
                <thead>
                  <tr className='text-center'>
                    <th scope="col">Điểm danh</th>
                    <th scope="col">Bài tập về nhà</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-center'>
                    <td>{dataSemester?.sumAttendancePercent} %</td>
                    <td>{dataSemester?.sumHomeWorkPercent} %</td>
                  </tr>
                </tbody>
              </table>
              <div dangerouslySetInnerHTML={{ __html: dataSemester.LearningComment }}></div>
              <h5>B. Quá trình học tập</h5>

              <table className="table table-bordered mb-0 border-secondary table-sm mb-3 ">
                <thead>
                  <tr className='text-center'>
                    <th scope="col">Nghe</th>
                    <th scope="col">Nói</th>
                    <th scope="col">Đọc</th>
                    <th scope="col">Viết</th>
                    <th scope="col">Tổng quan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-center'>
                    <td>{dataSemester?.ListeningScore}</td>
                    <td>{dataSemester?.SpeakingScore}</td>
                    <td>{dataSemester?.ReadingScore}</td>
                    <td>{dataSemester?.WritingScore}</td>
                    <td>{dataSemester?.OverallScore}</td>
                  </tr>
                </tbody>
              </table>
              <h6>1. Kỹ năng nghe</h6>
              <div dangerouslySetInnerHTML={{ __html: dataSemester.ListeningComment }}></div>
              <h6>2. Kỹ năng nói</h6>
              <div dangerouslySetInnerHTML={{ __html: dataSemester.SpeakingComment }}></div>
              <h6>3. Kỹ năng đọc</h6>
              <div dangerouslySetInnerHTML={{ __html: dataSemester.ReadingComment }}></div>
              <h6>4. Kỹ năng viết</h6>
              <div dangerouslySetInnerHTML={{ __html: dataSemester.WritingComment }}></div>
            </div>

          }
          {
            type !== EnumScheduleReportType.SemesterReport &&
            <CKEditor
              disabled={reportSchedule?.status === EnumScheduleReportStatus.Done}
              editor={ClassicEditor}
              config={editorConfiguration}
              data={props.values.students[index].message}
              onChange={(ev, ed) => props.setFieldValue(`students.${index}.message`, ed.getData())}
            />
          }

        </div>
      }

      return <></>

    }


  const handleUpdateSelectedStudent = (values: SubmitForm, selectedStudentId: string[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    let newValue = selectedStudentId.map(studentId => {
      let student = students.find(s => s.id === studentId);
      return {
        message: values.students.find(s => s.studentId === studentId)?.message ?? '',
        studentId: studentId,
        email: student?.email,
        fullName: `${student?.firstName} ${student?.lastName}`,
        parentEmail: student?.parentEmail,
        parentName: student?.parentName,
        parentPhone: student?.parentPhone,
        phoneNumber: student?.parentPhone
      } as ReportScheduleMessageForm
    })

    setFieldValue("students", newValue)

    setShowSelectStudent(false)
  }

  return (
    <>
      {
        !loading &&
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleConfirmed(values)}>
          {({ setFieldValue, submitForm, values }) => (
            <>
              <Modal show={show} onHide={handleClose} fullscreen backdrop="static">


                <>
                  <Modal.Header>
                    <Modal.Title className='d-flex align-items-center'>Danh sách lên lịch {reportSchedule?.jobName}

                      <a className='btn btn-light-primary btn-sm ms-2 w-100px d-flex align-items-center ' target='_blank' href={(DOWNLOAD_REPORT_API + reportSchedule?.id)}>Export
                        <span className="ms-2 fs-3 material-symbols-rounded">
                          download
                        </span>
                      </a>

                    </Modal.Title>

                    <div>
                      <button className='btn btn-secondary btn-sm me-2 w-100px' onClick={handleClose}>Quay lại</button>

                      {
                        reportSchedule?.status !== EnumScheduleReportStatus.Done && type !== EnumScheduleReportType.SemesterReport &&
                        <>
                          <button onClick={() => setShowSelectStudent(true)} className='btn btn-primary btn-sm me-2'>Thêm học sinh</button>

                          <button className='btn btn-success minw-100px btn-sm' onClick={submitForm}>Lưu</button>
                        </>


                      }

                    </div>
                  </Modal.Header>

                  <Modal.Body id="UpdateReportScheduleModal">
                    <DataTable
                      columns={columns}
                      data={values.students}
                      expandableRows
                      expandableRowsComponent={ExpandedComponent}
                      expandableRowsComponentProps={{ setFieldValue, values }}
                      noDataComponent={<div>Ấn vào <span className='fw-bold'>Thêm học sinh</span> để gửi báo cáo tới cho phụ huynh</div>}
                    />

                  </Modal.Body>
                </>


              </Modal>
              <SelectStudentModal
                show={showSelectStudent}
                handleClose={() => setShowSelectStudent(false)}
                students={students}
                selectedStudent={values.students}
                handleSelected={(e) => handleUpdateSelectedStudent(values, e, setFieldValue)} />
            </>
          )}
        </Formik>
      }
    </>
  )
}

export default ReportStudentScheduleDetailModal
